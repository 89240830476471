<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <v-form ref="form" v-model="valid">
          <v-textarea class="mt-2" hide-details="auto" v-model="note" :rules="[rules.req]" filled dense placeholder="Observaciones"></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="error" text @click.native="cancel">Cancelar</v-btn>
        <v-btn color="primary darken-1" text @click.native="agree">Crear</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { req } from "@/utils/validations.js";
export default {
  data: () => ({
    note:'',
    dialog: false,
    resolve: null,
    valid: true,
    reject: null,
    title: null,
    rules: {
      req,
    },
    options: {
      color: 'primary',
      width: 490,
      zIndex: 200
    }
  }),
  methods: {
    open(title, options, note) {
      this.note = note
      this.dialog = true
      this.valid = true
      this.title = title
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(this.note)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  }
}
</script>